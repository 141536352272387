const config = {
  gatsby: {
    pathPrefix: '/',
    siteUrl: 'https://books.sacredreflectionsofabeautyway.com',
    gaTrackingId: null,
    trailingSlash: false,
  },
  header: {
    logo: '/dahlia.svg',
    logoLink: '/',
    title:
      "<!--- <a href='https://sacredreflectionsofabeautyway.com/'><img class='img-responsive' src='src/dahlia.svg' alt='Learn logo' /></a>--->",
    githubUrl: '',
    helpUrl: '',
    tweetText: '',
    social: `  <!---<li>
		    <a href="https://twitter.com/hasurahq" target="_blank" rel="noopener">
		      <div class="twitterBtn">
		       <img src='https://graphql-engine-cdn.hasura.io/learn-hasura/assets/homepage/twitter-brands-block.svg' alt={'Twitter'}/> 
		      </div>
		    </a>
		  </li>
			<li>
		    <a href="https://discordapp.com/invite/hasura" target="_blank" rel="noopener">
		      <div class="discordBtn">
		        <img src='https://graphql-engine-cdn.hasura.io/learn-hasura/assets/homepage/discord-brands-block.svg' alt={'Discord'}/>
		      </div>
		    </a>
		  </li> --->`,
    links: [{ text: '', link: '' }],
    search: {
      enabled: true,
      indexName: 'sacredreflections',
      algoliaAppId: 'A170YHKKUV',
      algoliaSearchKey: 'abe429d9435893732850ba473abe68db',
      algoliaAdminKey: '9c937606988e92ce2824d7f6a811d217',
    },
  },
  sidebar: {
    forcedNavOrder: [
      '/introduction', // add trailing slash if enabled above
      '/codeblock',
      '/2005',
      '/2007',
      '/2008',
      '/2009',
      '/2010',
      '/2011',
      '/2012',
      '/2013',
      '/2014',
      '/2015',
      '/2016',
      '/2017',
      '/2018',
    ],
    collapsedNav: [
      '/codeblock', // add trailing slash if enabled above
      '/2005',
      '/2007',
      '/2008',
      '/2009',
      '/2010',
      '/2011',
      '/2012',
      '/2013',
      '/2014',
      '/2015',
      '/2016',
      '/2017',
      '/2018',
    ],
    links: [{ text: 'SRBW Website', link: 'https://sacredreflectionsofabeautyway.com/' }],
    frontLine: false,
    ignoreIndex: true,
    title: "<a href='https://sacredreflectionsofabeautyway.com/'>main site</a>",
  },
  siteMetadata: {
    title: 'Gatsby Gitbook Boilerplate | Hasura',
    description: 'Documentation built with mdx. Powering hasura.io/learn ',
    ogImage: null,
    docsLocation: 'https://github.com/hasura/gatsby-gitbook-boilerplate/tree/master/content',
    favicon: 'https://graphql-engine-cdn.hasura.io/img/hasura_icon_black.svg',
  },
  pwa: {
    enabled: true, // disabling this will also remove the existing service worker.
    manifest: {
      name: 'Gatsby Gitbook Starter',
      short_name: 'ABeautyWay',
      start_url: '/',
      background_color: '#6b37bf',
      theme_color: '#6b37bf',
      display: 'standalone',
      crossOrigin: 'use-credentials',
      icons: [
        {
          src: 'src/pwa-512.png',
          sizes: `512x512`,
          type: `image/png`,
        },
      ],
    },
  },
};

module.exports = config;
